import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// Components
import Loader from "../components/Loader/Loader"

const NotFound = () => {
  const [pageLoader, setPageLoader] = useState(true)

  useEffect(() => {
    let url = window.location.href
    if (url.includes("orders")) {
      window.location.href = url.replace(
        "https://store.thebettyrocker.com/",
        "https://checkout.thebettyrocker.com/",
      )
    } else {
      setPageLoader(false)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Page not found | The Betty Rocker</title>
      </Helmet>

      {/*loader*/}
      <Loader isLoader={pageLoader} />

      <div className="container error-container d-flex flex-column justify-content-center align-items-center py--15">
        <h1 className="h2 txt--center mb--30">
          The page you are looking for was not found.
        </h1>

        <Link to="/" className="btn btn--lg">
          Go To Homepage
        </Link>
      </div>
    </>
  )
}
export default NotFound
